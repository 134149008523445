.chat-container {
    z-index: 30;
    position: relative;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    gap: 1em;

    padding: 0.565em;
    padding-bottom: 1.125em;

    transition: all 0.08s ease-out;
}

.chat-container-default {
    width: 76.7875%;
    min-width: 23.125vw;
}

.chat-container-full {
    position: absolute;

    width: 100%;
    height: 100%;
}

.chat-history-scroll {
    box-sizing: border-box;
    /* height: max-content; */

    margin: 1em;
    overflow-y: scroll;
}

.chat-history-container {
    height: 100%;
    overflow-y: scroll;
}

.chat-input-box {
    min-height: fit-content;
    padding-top: 1.1375em; 
    padding-bottom: 0.7525em;
    padding-left: 0.525em;
    padding-right: 0.3125em;
}

.chat-history-stack {
    height: 100%;
}

