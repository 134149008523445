.pdf-document {
    height: 85.625vh;
    /* flex-grow: 1; */
    
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    border: 1px solid #888;

    overflow-x: scroll;
    overflow-y: scroll;
}

.pdf-container {
    /* height: 100%; */
    height: 85.625vh;
    /* box-sizing: border-box; */
    padding: 0.1em;

    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}