.login-page-container {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to bottom, #fff, #dee1ff);

    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
}

.login-page-container::-webkit-scrollbar {
    width: 0;
}

.login-paper {
    box-sizing: border-box;
    width: 36em;
    max-width: 93vw;
    height: 48em;
    max-height: 90vh;

    padding: 1.15em;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.login-page-form-group {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: 0.6667em 0em;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap !important;
    justify-content: flex-start;
    gap: 1.25em;
    overflow-y: scroll;
}
.login-page-form-group::-webkit-scrollbar {
    width: 0;
}