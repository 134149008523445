.chat-creation-modal-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: grid;
    place-items: center;
    overflow-y: scroll;
}

.chat-creation-modal {
    width: 36em;
    height: fit-content;
    padding: 1em;
    display: flex;
    flex-direction: column;
}

.chat-creation-form-container {
    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
}

.chat-creation-buttons-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1em;
}

.asset-creation-modal-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: grid;
    place-items: center;
    overflow-y: scroll;
}

.asset-creation-modal {
    width: 45em;
    height: 42em;
    padding: 1em;
    display: flex;
    flex-direction: column;
}

.asset-creation-form-container {
    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
}

.asset-creation-buttons-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1em;
}


.model-creation-modal-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: grid;
    place-items: center;
    overflow-y: scroll;
    scrollbar-width: none;
}

.model-creation-modal {
    width: 32em;
    height: 32em;
    padding: 1em;
    display: flex;
    flex-direction: column;
}

.model-creation-form-container {
    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
}

.model-creation-buttons-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1em;
}

.doc-creation-modal-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: grid;
    place-items: center;
    overflow-y: scroll;
    scrollbar-width: none;
}

.doc-creation-modal {
    width: 36em;
    height: 32.75em;
    padding: 1em;
    display: flex;
    flex-direction: column;
}

.doc-creation-form-container {
    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
}

.doc-creation-buttons-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
    margin-bottom: 1em;
    gap: 1em;
}
