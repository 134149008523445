.subtype-name-typography {
    width: 100% !important;
    min-width: fit-content !important;
    padding: 0 0.5em 0 0.1em !important;
}

/* .subtype-name-typography:hover {
    text-decoration: underline !important;
} */

.create-model-typography-inline {
    color: #0398e2 !important;
    padding-left: 1.6125em !important;
    padding-right: 0.5675em !important;
}

.create-model-typography-inline:hover {
    text-decoration: underline !important;
    cursor: pointer !important;
}

.subtype-model-count-typography {
    padding-left: 1.725em !important;
    padding-right: 0.6675em !important;
}

/* .subtype-model-count-typography:hover {
    text-decoration: underline !important;
} */

.create-model-button-entry {
    padding: 0 !important;
    margin-left: 1.490575em !important;
    padding-left: 1.4375em !important;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

.no-model-typography {
    padding: 0 0.3em 0.2em 0.3em !important;
    font-style: italic !important;
}

.create-model-button {
    padding: 0.1em 0.64em 0.1em 0.4em !important;
    box-sizing: border-box !important;
    border: 1px #ddd solid !important;
    border-radius: 4px !important;
}

.create-model-button-typography {
    width: 100% !important;
    padding: 0 !important;
    padding-left: 0.512em !important;
    line-height: 1.5 !important;
    text-transform: none !important;
}

.subtype-accordion-summary {
    padding-right: 1.1265em !important;
}

.subtype-accordion-summary-label {
    flex-grow: 1 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.type-model-count-typography {
    min-width: fit-content !important;
    margin-right: 0.6675em !important;
    padding-left: 1.725em !important;
    padding-right: 0.6675em !important;
}

.type-accordion-details {
    background-color: #fff !important;
    margin-left: 0.5em !important;
    padding-bottom: 0.4em !important;
}

.create-subtype-button {
    padding: 0.1em 0.4em 0.1em 0.2em !important;
    box-sizing: border-box !important;
    border: 1px #ddd solid !important;
    border-radius: 4px !important;
    text-transform: none !important;
}

.create-subtype-button-typography {
    width: 100% !important;
    padding: 0 !important;
    padding-left: 0.512em !important;
    line-height: 1.5 !important;

}