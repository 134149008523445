.frsbc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.app-container {
    box-sizing: border-box;
    height: 100vh;

    display: flex;
    flex-direction: column;
}

.main-container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    /* overflow: hidden; */
    padding-left: 0.375em;
    padding-right: 0.375em;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.left-sidebar-container {
    z-index: 10;
    box-sizing: border-box;
    width: 45.375%;
    height: 100%;

    min-width: 156px;
    max-width: 418px;
    
    padding: 0.43225em;
    padding-bottom: 48em;
    overflow-y: scroll;
    scrollbar-width: none;
}

.left-sidebar-container::-webkit-scrollbar {
    display: none;
}

.right-sidebar-container {
    z-index: 10;
    box-sizing: border-box;
    width: 100%;
    /* max-width: 10%; */
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    padding: 0.55em;
    /* border-left: 1px #0e0c0c solid; */
    overflow: hidden;
    /* border: 1px red solid; */
}


.branding-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 3px solid #292562;
    border-radius: 12px;
    margin-bottom: 1.125em;
    padding: 0.2625em;
}